<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          Filtros
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Buscar por"
              label-for="palavraChave"
            >
              <b-form-input
                id="palavraChave"
                v-model="palavraChaveFilter"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">Ação</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              Pesquisar
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro encontrado"
        :sort-desc.sync="isSortDirDesc"
        striped
      >

        <template #cell(nome)="data">
          {{ data.value }} {{ data.item.sobrenome }}
        </template>

        <template #cell(dataCriacao)="data">
          {{ formatDateBR(data.value) }}
        </template>

        <template #cell(dataUltimoLogin)="data">
          {{ formatDateTimeBR(data.value) }}
        </template>

        <template #cell(acao)="data">
          <feather-icon
            icon="SettingsIcon"
            @click.stop="editAssinaturaUser(data.item.id)"
          />
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ paginationMeta.from }} a {{ paginationMeta.to }} de {{ paginationMeta.of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>

import {
  BCard, BTable, BPagination, BRow, BCol, BCardHeader, BCardBody, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import store from '@/store'
import { formatDateBR, formatDateTimeBR } from '@/utils/filter'
import utilsMixin from '@/mixins/utilsMixin'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import assinanteStoreModule from './assinanteStoreModule'

const CONTROLE_ASSINANTE_STORE_MODULE_NAME = 'controle-assinante'

export default {

  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatDateBR,
      formatDateTimeBR,
      tableColumns: [
        { key: 'nome', label: 'Nome' },
        { key: 'email', label: 'Email' },
        { key: 'dataCriacao', label: 'Entrou em' },
        { key: 'dataUltimoLogin', label: 'Último login' },
        { key: 'acao', label: 'Ações' },
      ],
      perPage: 15,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'nome,desc',
      isSortDirDesc: true,
      dataList: [],
      palavraChaveFilter: null,
    }
  },

  computed: {

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },

    windowWidth() {
      return this.$store.state.app.windowWidth
    },
  },

  watch: {
    currentPage() {
      this.fetchList()
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(CONTROLE_ASSINANTE_STORE_MODULE_NAME)) store.registerModule(CONTROLE_ASSINANTE_STORE_MODULE_NAME, assinanteStoreModule)
    this.fetchList()
  },

  beforeDestroy() {
    if (store.hasModule(CONTROLE_ASSINANTE_STORE_MODULE_NAME)) store.unregisterModule(CONTROLE_ASSINANTE_STORE_MODULE_NAME)
  },

  methods: {

    editAssinaturaUser(id) {
      this.$router.push(`/controle/assinante/${id}`).catch(() => {})
    },

    statusColor(value) {
      if (value === 'Aguardando ativação') return 'warning'
      if (value === 'Ativo') return 'success'
      if (value === 'Inativo') return 'danger'
      if (value === 'Expirado') return 'danger'
      return 'warning'
    },

    buscarFiltro() {
      this.page = 1
      this.fetchList()
    },

    fetchList() {
      store.dispatch('controle-assinante/getAssinantes', {
        size: this.perPage,
        page: this.currentPage - 1,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        chave: this.palavraChaveFilter,
      })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
